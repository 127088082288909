body {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.pointsContainer {
  & > div {
    margin-bottom: 20px;
  }
}
.divisionCard {
  padding-top: 10px;
  padding-left: 5px;
  border: 1px solid grey;
  min-width: 300px;
  .divisionHead {
    font-weight: bold;
  }
}
.wideDropdown {
  min-width: 300px;
}
.wideText {
  min-width: 300px;
}